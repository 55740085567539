<template>
	<div class="table-responsive-md">
		<table class="table table-bordered table-hover table-responsive table-fit">
			<tbody>
				<tr>
					<td class="fit text-center">{{ $t("OrderFieldTable.Type") }}</td>
					<td v-for="type in orderTypes" :key="type.id" class="text-center">{{ type.id }}</td>
				</tr>
				<tr>
					<td class="fit text-center">{{ $t("OrderFieldTable.Amount") }}</td>
					<td v-for="(type, index) in orderTypes" :key="`${type.id}-${index}`" class="text-center">
						<AmountItem
							:id="type.id"
							:disabled="disabled"
							:value="type.amount"
							:new-update-has-status-revision="newUpdateHasStatusRevision"
							@amount-change="amountChange"
						/>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	name: "TableAmount",
	components: {
		AmountItem: () => import("./AmountItem"),
	},
	props: {
		disabled: {
			type: Boolean,
			default: false,
		},
		newUpdateHasStatusRevision: {
			type: Boolean,
			default: false,
		},
		orderTypes: {
			type: Array,
			default: () => [],
		},
	},
	mounted() {},
	methods: {
		amountChange() {
			this.$emit("amount-change")
		},
	},
}
</script>
<style lang="scss" scoped>
.table td.fit {
	white-space: nowrap;
	width: 5%;
}
</style>
